import React from 'react'
import { ContactView } from '@views'
import { Seo } from '@atoms'

export const Head = () => {
  return <Seo title={'Contact'} />
}

const Contact = () => {
  return <ContactView />
}

export default Contact
